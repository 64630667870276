import { Breadcrumb, Button, Modal, Row, Col, Tabs, Tab } from 'react-bootstrap'
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom'
import React, { useState } from "react";
import { getEmployeeAssignmentDetail, deleteEmployeeAssignment, getAllIncomesOfEmployeeAssignment, getCurrentEmployeeAssignmentIncome } from '../../ApiConnection'
import { formatDate, calculateTimeBetween, getHelmet } from '../../UtilFunctions';
import { useAuthStore } from '../../stores/AuthStore'
import EmployeeAssignmentIncomeTable from '../income/EmployeeAssignmentIncomeTable';
import { CostIncomeBarChart, IncomeBarChart } from '../charts';
import { observer } from 'mobx-react';

const EmployeeAssignmentDetail = () => {
    const { token, tenant, roles } = useAuthStore();
    let { id } = useParams();
    const [assignment, setAssignment] = React.useState(null);
    const [incomes, setIncomes] = useState([]);
    const [currentIncome, setCurrentIncome] = useState(null);
    const [show, setShow] = React.useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleDelete = async (id) => {
        await deleteEmployeeAssignment(token, tenant, id);
        const path = `/assignments`;
        navigate(path);
    }

    const routeChange = (id) => {
        const path = `/assignments/edit/${id}`;
        navigate(path);
    }

    const Helmet = () => {
        return getHelmet('Cloudway Accounts - Employee Assignment Details', 'Details of an employee assignment', true);
    }

    const getEmployeeAssignmentIncomeData = async () => {
        try {
            setIncomes(await getAllIncomesOfEmployeeAssignment(token, tenant, id));
            setCurrentIncome(await getCurrentEmployeeAssignmentIncome(token, tenant, id));
        } catch (e) {
            setCurrentIncome('NO INCOME');
        }
    }

    React.useEffect(() => {
        async function getData() {
            try {
                setAssignment(await getEmployeeAssignmentDetail(token, tenant, id));
                if (roles.includes('Sales')) getEmployeeAssignmentIncomeData();
            } catch (e) {
                navigate(`/NotAuthenticated/${e.response.status}`, {
                    state: { prevLocation: location.pathname }
                });
            }
        }
        getData()
    }, []);

    if (!assignment) return null;

    return (
        <div>
            <Helmet />
            <Breadcrumb className="mt-3">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >Home</Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/Assignments" }} >Assignments</Breadcrumb.Item>
                <Breadcrumb.Item active>Employee Assignment Details</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h5>Employee assignment details</h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Employee</small>
                    <p><Link to={`/employees/${assignment.employee_id}`}>{assignment.employee_name} {assignment.employee_lastname}</Link></p>
                </Col>
                <Col>
                    <small className='text-muted'>Customer</small>
                    <p><Link to={`/customers/${assignment.customer_id}`}>{assignment.customer_name}</Link></p>
                </Col>
                <Col>
                    <small className='text-muted'>Project</small>
                    <p><Link to={`/projects/${assignment.project_id}`}>{assignment.project_name}</Link></p>
                </Col>
            </Row>
            <Row>
                <Col xs="4">
                    <small className='text-muted'>Start date</small>
                    <p>{formatDate(assignment.start_date)}</p>
                </Col>
                <Col xs="3">
                    <small className='text-muted'>End date</small>
                    <p>{formatDate(assignment.end_date)}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Allocation percentage</small>
                    <p>{assignment.allocation_percentage * 100}%</p>
                </Col>
                <Col>
                    <small className='text-muted'>Renewal expected</small>
                    <p>{assignment.renewal_expected ? 'Yes' : 'No'}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Billable</small>
                    <p>{assignment.billable ? 'Yes' : 'No'}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Created</small>
                    <p>{calculateTimeBetween(assignment.created_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Created by</small>
                    <p>{assignment.created_by}</p>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <small className='text-muted'>Last modified</small>
                    <p>{calculateTimeBetween(assignment.modified_at)}</p>
                </Col>
                <Col>
                    <small className='text-muted'>Last modified by</small>
                    <p>{assignment.last_modified_by}</p>
                </Col>
                {roles.includes('Sales') &&
                    <Col>
                        <small className='text-muted'>Current daily income</small>
                        <p>{currentIncome?.amount ? `€${currentIncome.amount}` : currentIncome}</p>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => routeChange(assignment.id)} variant="dark">Edit</Button>{' '}
                    <Button onClick={handleShow} variant="dark">Remove</Button>
                </Col>
            </Row>
            {roles.includes('Sales') &&
                <Tabs defaultActiveKey="assignmentsvisualization" className="mb-3 mt-2" id="uncontrolled-tab-example">
                    {roles.includes('Financial') ?
                        <Tab eventKey="costIncomeChart" title="Cost/Income visualization">
                            <h5>Cost-Income</h5>
                            <CostIncomeBarChart on='assignment' refresh />
                        </Tab>
                    :
                        <Tab eventKey="costIncomeChart" title="Income visualization">
                            <h5>Income</h5>
                            <IncomeBarChart on='assignment' refresh />
                        </Tab>
                    }
                    <Tab eventKey="incomes" title="Income">
                        <div className='d-flex'>
                            <h5>Employee Assignment Income</h5>
                            <Button className="w-25 ms-auto" onClick={() => navigate(`/assignments/${id}/income/create`)} variant="dark">Add Daily Income</Button>
                        </div>
                        <EmployeeAssignmentIncomeTable incomes={incomes} refreshEmployeeAssignmentIncomes={getEmployeeAssignmentIncomeData} current={currentIncome?.id} ></EmployeeAssignmentIncomeTable>
                    </Tab>
                </Tabs>
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee Assignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to delete this Employee Assignment!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={() => { handleDelete(assignment.id) }}>
                        Delete employee assignment
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default observer(EmployeeAssignmentDetail);
