import Navigationbar from '../navigationbar/Navigationbar';
import Contact from './Contact';
import Home from './Home';
import NotAuthenticated from './authentication/NotAuthenticated';
import Login from './authentication/Login';
import Logout from'./authentication/Logout';
import Dashboard from './Dashboard';
import Settings from './Settings';
import * as employeeComponents from './employee';
import * as customerComponents from './customer';
import * as chartComponents from './charts';
import * as employeeAssignmentComponents from './assignment';
import * as projectComponents from './project';
import * as touchPointComponents from './touchpoint';
import * as adminComponents from './admin';
import * as contactComponents from './contact/index';
import * as costComponents from './cost';
import * as incomeComponents from './income';
import * as manageComponents from './manage';

export const Components = ({
    Navigationbar,
    Contact,
    Home,
    Dashboard,
    NotAuthenticated,
    Login,
    Logout,
    Settings,
    ...employeeAssignmentComponents,
    ...chartComponents,
    ...customerComponents,
    ...employeeComponents,
    ...projectComponents,
    ...touchPointComponents,
    ...adminComponents,
    ...contactComponents,
    ...costComponents,
    ...incomeComponents,
    ...manageComponents,
}) ;

  